// @ts-nocheck
import { IEnvironment } from "./environment.interface";
import { LogLevel } from "@azure/msal-browser";

export const environment: IEnvironment = {
  production: 'false' === 'true',
  apiUrl: 'https://permitting-tool-api-dev.azurewebsites.net',
  clientId: 'da3defaf-37b8-465d-affe-9ec0c542de97',
  tenant: '3a86ebff-1254-450e-bd5e-161fedab6b9a',
  msalLogLevel: LogLevel['Error'],
  maximoProxy: 'https://dw-inspections-mw-dev.azurewebsites.net/api/'
};
